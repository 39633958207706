.badge :global .MuiBadge-badge {
  border: 1px solid var(--color-background-main);
  border-radius: 50%;
  box-sizing: content-box;
  right: 12px;
  top: 8px;
  background-color: var(--color-secondary-main);
  height: 6px;
  min-width: 6px;
}

.count {
  margin-left: calc(var(--space-1) / 2);
  background-color: var(--color-success-light);
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-static-main);
}
