.wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-2);
  margin-bottom: var(--space-1);
}

.icon {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.sign {
  background-color: var(--color-info-background);
}

.sign svg {
  color: var(--color-info-dark);
}

.execute {
  background-color: var(--color-secondary-background);
}

.execute svg {
  color: var(--color-secondary-dark);
}

[data-theme='dark'] .execute {
  background-color: var(--color-success-background);
}

[data-theme='dark'] .execute svg {
  color: var(--color-success-dark);
}

.params {
  margin-bottom: var(--space-2);
}

.noBottomBorderRadius :global(.MuiPaper-root) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noTopBorder > div {
  margin-top: -1px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.mobileTxCheckMessages,
.mobileTxCheckMessages:empty {
  display: none;
}

@media (max-width: 899.95px) {
  .mobileTxCheckMessages {
    display: block;
  }
}

.zodiac {
  color: var(--color-text-primary);
}

.roleChip {
  background-color: var(--color-background-main);
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 8px;
}
