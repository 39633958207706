.card {
  position: relative;
  flex-basis: 50%;
  display: grid;
  grid-template-areas:
    'logo logo actions'
    'info info actions';
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  padding: var(--space-2);
}

.card.compact {
  grid-template-areas:
    'logo info actions'
    'logo info actions';
  grid-template-columns: auto 1fr auto;
  padding: var(--space-1);
  padding-left: 12px;
  border: 0;
}

.initialsAvatar {
  grid-area: logo;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  justify-self: start;
}

.spaceInfo {
  grid-area: info;
}

.dot {
  width: 2px;
  height: 2px;
  background-color: var(--color-border-main);
  border-radius: 50%;
}

.spaceActions {
  grid-area: actions;
  align-self: start;
}

.cardLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
