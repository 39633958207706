.header {
  padding: var(--space-4) var(--space-3);
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.content {
  padding: var(--space-2);
}

.status {
  padding: 0;
}

.status::before {
  content: '';
  position: absolute;
  border-left: 2px solid var(--color-border-light);
  left: 15px;
  top: 20px;
  height: calc(100% - 40px);
}

.status :global .MuiListItem-root:first-of-type {
  padding-top: 0;
}

.status :global .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.status :global .MuiListItemIcon-root {
  color: var(--color-primary-main);
  justify-content: center;
  min-width: 32px;
  padding: var(--space-1);
  background-color: var(--color-background-paper);
}

[data-theme='light'] .status :global .MuiListItemIcon-root {
  color: var(--color-secondary-dark);
}

.incomplete > * {
  color: var(--color-text-secondary) !important;
}

.close {
  color: var(--color-border-main);
  padding: var(--space-2);
  border-left: 1px solid var(--color-border-light);
  border-radius: 0;
  margin-left: auto;
  display: none;
}

.badge {
  margin-left: var(--space-1);
  padding-right: 2px;
  border-radius: 50%;
  width: var(--space-3);
  height: var(--space-3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary-main);
  color: var(--color-text-primary);
}

[data-theme='dark'] .badge {
  background-color: var(--color-primary-main);
  color: var(--color-text-secondary);
}

@media (max-width: 899.95px) {
  .header {
    padding: 0;
    flex-direction: row;
  }

  .logo {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }

  .close {
    display: flex;
  }

  .content {
    padding: var(--space-3);
  }
}
