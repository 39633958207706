.amount {
  margin-bottom: var(--space-1);
}
.partsBlock {
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
  padding: calc(var(--space-1) - 6px) var(--space-2);
  margin-top: var(--space-1);
}

.partsBlockTitle {
  padding: var(--space-1) 0;
}

.numberOfPartsLabel {
  display: inline-block;
  border-radius: 4px;
  padding: 2px 8px;
  background-color: var(--color-border-background);
}
