.signerForm :global .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--color-border-light) !important;
}

.disabledPill {
  background-color: var(--color-border-light);
  border-radius: 4px;
  color: var(--color-text-primary);
  padding: 4px 8px;
}
