.container {
  margin-top: 10px;
}

.header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-border-light);
}

.step {
  position: relative;
}

/* Back button */
.backButton {
  position: absolute;
  left: var(--space-3);
  bottom: var(--space-3);
}

.step :global(.MuiCard-root:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: 0;
}

/* Submit button */
.step :global(.MuiCardActions-root) {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: var(--space-3);
}

.step :global(.MuiCardActions-root) > * {
  align-self: flex-end;
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
  margin-right: var(--space-2);
}

.icon svg {
  height: 16px;
  width: auto;
}

.step :global(.MuiAccordionSummary-content),
.step :global(.MuiAccordionSummary-content) p {
  font-weight: bold;
  font-size: 14px;
}

.step :global(.MuiAccordionSummary-expandIconWrapper) {
  margin-left: var(--space-2);
}

.statusButton {
  position: absolute;
  top: 0;
  right: 57px;
  color: var(--color-text-primary);
  padding: var(--space-2);
  border-left: 1px solid var(--color-border-light);
  border-right: 1px solid var(--color-border-light);
  border-radius: 0;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  display: none;
}

.sticky {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  position: sticky;
  top: var(--space-2);
  margin-top: var(--space-2);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-2);
}

.widget {
  /* Height of transaction type title */
  margin-top: 46px;
}

@media (max-width: 1199px) {
  .backButton {
    left: 50%;
    transform: translateX(-50%);
  }

  .step :global(.MuiCardActions-root) {
    margin-bottom: var(--space-8);
  }
}

@media (max-width: 899.95px) {
  .widget {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    margin-top: unset;
  }

  .widget.active {
    z-index: 1;
  }

  .widget :global .MuiPaper-root {
    height: 100%;
  }

  .titleWrapper {
    position: absolute;
    top: 16px;
    left: var(--space-2);
    margin-bottom: 0;
    width: calc(100% - 145px);
  }

  .title {
    font-size: 16px;
    line-height: 18px;
  }

  .container {
    padding: 0;
  }

  .progressBar {
    display: none;
  }

  .step :global(.MuiCard-root),
  .header {
    border-radius: 0;
  }

  .statusButton {
    display: inline-flex;
  }
}
