.container {
  background-color: var(--color-warning-background);
  border-radius: 4px;
  padding: var(--space-2);
}

.warningAccordion {
  border: 1px var(--color-warning-main) solid !important;
  margin-bottom: var(--space-2) !important;
}
.warningAccordion:hover {
  border: 1px var(--color-warning-main) solid !important;
}

.warningAccordion :global .Mui-expanded.MuiAccordionSummary-root,
.warningAccordion :global .MuiAccordionSummary-root:hover {
  background-color: var(--color-warning-background);
}

.alert {
  width: 100%;
}

.approvalField {
  background-color: var(--color-background-paper);
  border-radius: 6px;
  padding: 12px 16px;
  width: 100%;
}

.alert :global .MuiAlert-message {
  width: 100%;
}

.zeroValueApproval {
  display: none;
}

.approvalAmount {
  padding-left: var(--space-1);
}

.approvalsList {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-2);
}

.icon {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--color-warning-background);
}

.icon svg {
  color: var(--color-warning-main);
}
