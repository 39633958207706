.container {
  font-size: 0.76em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1px;
  color: rgb(93, 109, 116);
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 4px;
}
