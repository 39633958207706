.wrapper {
  border: 1px solid var(--color-border-light);
}

.accordion {
  border: unset;
}

.accordion {
  background-color: unset !important;
}

.accordion button {
  opacity: 1 !important;
}

.summary {
  background-color: unset !important;
}

.header {
  font-weight: 700;
}

.details {
  padding-top: 0;
  padding-bottom: 12px;
}

.button {
  padding: var(--space-2);
  padding-top: 4px;
  padding-bottom: 4px;
}
