.container {
  height: 36px;
  display: flex;
  align-items: center;
  background-color: var(--color-background-paper);
  border-bottom: 1px solid var(--color-border-light);
  padding: var(--space-1) var(--space-3);
  gap: var(--space-1);
}

.container:empty {
  display: none;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: calc(var(--space-1) / 2);
}
