.gridContainer {
  --grid-nonce: minmax(45px, 0.25fr);
  --grid-type: minmax(150px, 3fr);
  --grid-info: minmax(190px, 3fr);
  --grid-date: minmax(200px, 3fr);
  --grid-confirmations: minmax(120px, 1fr);

  --grid-status: minmax(120px, 1fr);
  --grid-actions: minmax(100px, 1fr);

  width: 100%;
  display: grid;
  gap: var(--space-2);
  align-items: center;
  white-space: nowrap;
  grid-template-columns:
    var(--grid-nonce) var(--grid-type) var(--grid-info) var(--grid-date) var(--grid-confirmations)
    var(--grid-status) var(--grid-actions);
  grid-template-areas: 'nonce type info date confirmations status actions';
}

.gridContainer > * {
  max-width: 100%;
}

.gridContainer.history {
  grid-template-columns: var(--grid-nonce) var(--grid-type) var(--grid-info) var(--grid-date) var(--grid-status);
  grid-template-areas: 'nonce type info date status';
}

.gridContainer.conflictGroup {
  grid-template-columns:
    var(--grid-type) var(--grid-info) var(--grid-date) var(--grid-confirmations) var(--grid-status)
    var(--grid-actions);
  grid-template-areas: 'type info date confirmations status actions';
}

.gridContainer.bulkGroup {
  grid-template-columns: var(--grid-type) var(--grid-info) var(--grid-date) var(--grid-status);
  grid-template-areas: 'type info date status';
}

.gridContainer.bulkGroup.untrusted {
  grid-template-columns: var(--grid-nonce) minmax(200px, 2.4fr) var(--grid-info) var(--grid-date) var(--grid-status);
  grid-template-areas: 'nonce type info date status';
}

.gridContainer.message {
  grid-template-columns: var(--grid-type) var(--grid-info) var(--grid-date) var(--grid-status) var(--grid-confirmations);
  grid-template-areas: 'type info date status confirmations';
}

.gridContainer.untrusted :not(:first-child):is(div) {
  opacity: 0.4;
}

.gridContainer .status {
  margin-right: var(--space-3);
  display: flex;
  justify-content: flex-end;
}

.date {
  color: var(--color-text-secondary);
}

@media (max-width: 1350px) {
  .gridContainer {
    gap: var(--space-1);
    display: flex;
    flex-wrap: wrap;
  }

  .nonce {
    min-width: 30px;
  }

  .date {
    width: 100%;
  }

  .status {
    margin: 0 var(--space-1);
  }
}
