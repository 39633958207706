.withExecutionMethod {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.error :global .MuiAccordion-root.Mui-expanded {
  border-color: var(--color-error-light);
}

.error :global .MuiAccordionSummary-root.Mui-expanded {
  background-color: var(--color-error-background);
  border-bottom: 1px solid var(--color-error-light);
}

.error :global .MuiAccordionSummary-expandIconWrapper {
  margin-left: var(--space-1);
}
