.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
}

.container:first-of-type {
  margin-top: 0;
}
